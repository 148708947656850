<template>
  <div id="app">
    <Tabs></Tabs>
    <!-- <img class="banner" src="../../assets/img/about.jpg" alt="勤学教育" /> -->
    <img class="banner" :src="bannerImg" alt="勤学教育" />
    <div id="content">
      <div class="about_box">
        <ul class="about_txt">
          <li>
            <span>勤学教育,</span>
            <!-- <span
              >中国网络学历教育服务及智慧教育综合服务提供者。着力于以大数据、云计算、人工智能等信息技术为支撑，创新教育产品、构建智慧教育模式，为用户提供多元化和定制化的教育解决方案。</span
            >
          </li>
          <li>
            自成立以来，勤学教育全面布局，在网络高等教育、成教信息化、高校信息化、IT教育和企业培训等领域稳步推进，在全国建设标准化学习中心60余家，整合名校课程10000余门。服务院校200余所、企业500余家、网络大学生超1000万。
          </li>
          <li>
            秉承“诚信、务实、创新、双赢、开放、信任、拥抱变化”价值观，不断为用户创造更高价值。同时，坚持“弘扬教育、成就人生”的使命，努力成为学习者喜爱的教育服务机构。
          </li> -->
            <span
              >勤学教育成立于2010年，是国内兼具口碑与实力的职业教育培训机构，主要从事建造师，注册安全工程师，执业药师等领域的职业考试培训服务。</span
            >
          </li>
          <li>
            经过十几年的发展，创办勤学课堂等智能化在线学习APP，为每一位学员提供高效、便捷、专业的全方位服务。
          </li>
          <li>
            目前勤学教育业务覆盖全国30个省级行政区域，秉承“让人人享有优质教育”的理念，不忘初心，砥砺前行...
          </li>
        </ul>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import api from '@/api/api'
export default {
  data() {
    return {
      bannerImg: "",
    };
  },
  created() {
    this.getpic()
  },

  methods: {
    //首页banner
    getpic(){
      api.pic({position:2}).then(res => {
        this.bannerImg = res.data.data.list[0].image
      })
    },
  },

};
</script>

<style lang="scss" scoped>
/* banner */

.banner {
  width: 100%;
  height: 400px;
}

/* 内容区 */

#content {
  width: 1200px;
  /* background: #eee; */
  margin: auto;
}

.about_box {
  width: 1200px;
  height: 500px;
  box-shadow: 0 8px 12px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 160px;
  overflow: hidden;
}

#content .about_txt {
  width: 1100px;
  height: 250px;
  /* height: 1000px; */
  margin: 69px auto 0;
}

#content .about_txt li {
  font-size: 18px;
  font-family: DFPSongW7-GB;
  font-weight: 400;
  color: #333333;
  line-height: 32px;
  margin-bottom: 40px;
}

#content .about_txt li span:nth-child(1) {
  width: 167px;
  height: 41px;
  font-size: 40px;
  font-family: DFPSongW9-GB;
  font-weight: 400;
  color: #3e7eff;
  line-height: 32px;
  margin-right: 10px;
}
</style>
